import React from 'react';
import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { PropTypes } from 'prop-types';
// import TopbarNavDashboards from './TopbarNavDashboards';
// import TopbarNavUIElements from './TopbarNavUIElements';
// import TopbarNavOtherPages from './TopbarNavOtherPages';

const isMaintenance = process.env.REACT_APP_MAINTENANCE === 'true';
const isDotCom = process.env.REACT_APP_DOTCOM === 'true';

const TopbarNav = ({ t }) => (
  /* <nav className="topbar__nav">
    <Link className="topbar__nav-link" to="/home">Home</Link>
    <Link className="topbar__nav-link" to="/about-us">About Us</Link>
    <Link className="topbar__nav-link" to="/contact">Contact Us</Link>
  </nav> */
  <nav className="topbar__menu-nav">
    {/* <Link to="/home">
      <button>
        {t('footer.home')}
      </button>
    </Link> */}
    {!isMaintenance && <Link to="/world-cup">
      <button>
        {t('footer.world_cup')}
      </button>
    </Link>}
    {!isMaintenance && <Link to="/courses">
      <button>
        {t('footer.weacourses')}
      </button>
    </Link>}
    {!isMaintenance && !isDotCom && <Link to="/news">
      <button>
        {t('footer.other_events')}
      </button>
    </Link>}
    {!isMaintenance && !isDotCom && <Link to="/donation">
      <button>
        {t('footer.donation')}
      </button>
    </Link>}
    {/* {!isMaintenance && <Link to="/teacher-training">
      <button>
        {t('footer.training')}
      </button>
    </Link>} */}
    {!isMaintenance && <Link to="/about-us">
      <button>
        {t('footer.aboutus')}
      </button>
    </Link>}
    {/* <Link to="/ourteam">
      <button>
        {t('footer.ourteam')}
      </button>
    </Link> */}
    {/* {!isMaintenance && <Link to="/events/member">
      <button>
        {t('footer.memberships')}
      </button>
    </Link>} */}
    {/* <Link to="/memberships">
      <button>
        {t('footer.memberships')}
      </button>
    </Link> */}
    {!isMaintenance && <Link to="/contact">
      <button>
        {t('footer.contact')}
      </button>
    </Link>}
    {/* <a href="https://store.joyajoy.com/signin" target="_blank" rel="noopener noreferrer">
      <button>
        Sign In
      </button>
    </a> */}
  </nav>
);

TopbarNav.propTypes = {
  t: PropTypes.func.isRequired,
};

export default (withTranslation('common')(TopbarNav));
