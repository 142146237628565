import React, { Suspense, lazy } from "react";
import { Route, Switch, Redirect } from "react-router-dom";
import MainWrapper from "./MainWrapper";
import Layout from "../Layout";
// import VaccineEvent from "../Events/Vaccine";

const Home = lazy(() => import("../Home"));
// const LearnMore = lazy(() => import("../LearnMore"));
const Copyright = lazy(() => import("../Copyright"));
const LegalStatements = lazy(() => import("../LegalStatements"));
const Partnership = lazy(() => import("../PartnerBenefit"));
// const OurTeam = lazy(() => import("../OurTeam"));
// const Terms = lazy(() => import("../Terms"));
// const TermsBible = lazy(() => import("../Termsbible"));
// const VideoPlayer = lazy(() => import("../VideoPlayer"));
// const JoinTemp = lazy(() => import("../JoinTemp"));
// const CoursesWriting = lazy(() => import("../CoursesWriting"));
// const CoursesCN = lazy(() => import("../CoursesCN"));
// const CoursesEN = lazy(() => import("../CoursesEN"));
// const CoursesEV = lazy(() => import("../CoursesEV"));
// const CoursesFun = lazy(() => import("../CoursesFun"));
// const CoursesPhonics = lazy(() => import("../CoursesPhonics"));
// const MemberShips = lazy(() => import("../Memberships"));
// const Promo = lazy(() => import("../Promo"));
const SiteMap = lazy(() => import("../SiteMap"));
const FormSuccess = lazy(() => import("../FormSuccess"));
const DynamicPage = lazy(() => import("../Page/DynamicPage"));

const wrappedRoutes = () => (
  <div>
    <Layout />
    <div className="container__wrap">
      <Switch>
        <Route exact path="/home" component={Home} />
        {/* <Route exact path="/writing" component={CoursesWriting} />
        <Route exact path="/coursescn" component={CoursesCN} />
        <Route exact path="/coursesen" component={CoursesEN} />
        <Route exact path="/coursesev" component={CoursesEV} />
        <Route exact path="/coursesfun" component={CoursesFun} />
        <Route exact path="/coursesphonics" component={CoursesPhonics} />
        <Route exact path="/memberships" component={MemberShips} />
        <Route exact path="/ourteam" component={OurTeam} />
        <Route exact path="/learnmore" component={LearnMore} /> */}
        <Route
          exact
          path="/privacy"
          render={() => <Redirect exact to="/privacy-policy" />}
        />
        <Route exact path="/copyright" component={Copyright} />
        <Route exact path="/legalstatements" component={LegalStatements} />
        <Route exact path="/partners" component={Partnership} /> 
        {/* <Route exact path="/terms" component={Terms} /> */}
        {/* <Route exact path="/termsbible" component={TermsBible} /> */}
        {/* <Route exact path="/videoplayer" component={VideoPlayer} /> */}
        {/* <Route exact path="/join" component={JoinTemp} /> */}
        {/* <Route exact path="/promo" component={Promo} /> */}
        {/* <Route exact path="/:region/application/organization/info" component={OrganizationApplicationInfo} />
        <Route exact path="/:region/application/organization/form" component={OrganizationApplicationFormPage} />
        <Route exact path="/:region/application/organization/terms" component={OrganizationApplicationTerms} />
        <Route exact path="/:region/application/organization/success" component={OrganizationApplicationSuccess} />
        <Route exact path="/:region/application/parent/info" component={ParentApplicationInfo} />
        <Route exact path="/:region/application/parent/form" component={ParentApplicationFormPage} />
        <Route exact path="/:region/application/parent/terms" component={ParentApplicationTerms} />
        <Route exact path="/:region/application/parent/success" component={ParentApplicationSuccess} /> */}
        {/* <Route exact path="/wea_luckydraw" component={VaccineEvent} />
        <Route exact path="/wea_luckydraw_details" component={VaccineInfo} /> */}

        <Route exact path="/form-success" component={FormSuccess} />
        <Route exact path="/sitemap" component={SiteMap} />
        <Route exact path="/events/hk-early-registration">
          <Redirect to="/weaworldcup_prepurchase_en-hk" />
        </Route>
        <Route exact path="/:pageId+" component={DynamicPage} />
        <Route path="" render={() => <Redirect exact to="/home" />} />
      </Switch>
    </div>
  </div>
);

const Router = () => (
  <MainWrapper>
    <main>
      <Suspense fallback={<div />}>
        <Switch>
          {/* pages from above don't show topbar, otherwise wrap routes */}
          <Route path="/" component={wrappedRoutes} />
        </Switch>
      </Suspense>
    </main>
  </MainWrapper>
);

export default Router;
