import React, { useState } from 'react';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
// import { Link } from 'react-router-dom';
// import DownIcon from 'mdi-react/ChevronDownIcon';
import { Collapse } from 'reactstrap';
import TopbarMenuLink from './TopbarMenuLink';

/* class TopbarProfile extends PureComponent {
  static propTypes = {
    t: PropTypes.func.isRequired,
  };

  constructor() {
    super();
    this.state = {
      collapse: false,
    };
  }

  toggle = () => {
    this.setState({ collapse: !this.state.collapse });
  };

  render() {
    const { t } = this.props;
    return (
      <div className="topbar__profile">
        <button className="topbar__avatar" onClick={this.toggle}>
          <img className="topbar__avatar-img" src={`${process.env.PUBLIC_URL}/img/wea_menu.png`} alt="Menu" />
        </button>
        {this.state.collapse && <button className="topbar__back" onClick={this.toggle} />}
        <Collapse isOpen={this.state.collapse} className="topbar__menu-wrap">
          <div className="topbar__menu">
            <TopbarMenuLink title={t('footer.home')}  image="wea_home.png" exact path="/home" onClick={this.toggle} />
            <img className="menu__line" src={`${process.env.PUBLIC_URL}/img/wea_menu_line.png`} alt="Menu-line" />
            <TopbarMenuLink title={t('footer.world_cup')}  image="wea_world_cup.png" exact path="/world-cup" onClick={this.toggle} />
            <img className="menu__line" src={`${process.env.PUBLIC_URL}/img/wea_menu_line.png`} alt="Menu-line" />
            <TopbarMenuLink title={t('footer.weacourses')}  image="wea_interactive_course.png" exact path="/courses" onClick={this.toggle} />
            <img className="menu__line" src={`${process.env.PUBLIC_URL}/img/wea_menu_line.png`} alt="Menu-line" />
            <TopbarMenuLink title={t('footer.training')}  image="wea_teacher_training.png" exact path="/teacher-training" onClick={this.toggle} />
            <img className="menu__line" src={`${process.env.PUBLIC_URL}/img/wea_menu_line.png`} alt="Menu-line" />
            <TopbarMenuLink title={t('footer.aboutus')}  image="wea_about_us.png" exact path="/about-us" onClick={this.toggle} />
            <img className="menu__line" src={`${process.env.PUBLIC_URL}/img/wea_menu_line.png`} alt="Menu-line" />
            <TopbarMenuLink title={t('footer.contact')} icon="phone-handset" image="wea_contact.png" exact path="/contact" onClick={this.toggle} />
          </div>
        </Collapse>
      </div>
    );
  }
} */

const isMaintenance = process.env.REACT_APP_MAINTENANCE === 'true';
const isDotCom = process.env.REACT_APP_DOTCOM === 'true';

const TopbarProfile = ({
  t,
}) => {
  const [collapse, setCollapse] = useState(false);

  return !isMaintenance && (
    <div className="topbar__profile">
      <button className="topbar__avatar" onClick={() => setCollapse(!collapse)}>
        {/* <img className="topbar__avatar-img" src={!session.account ? EmptyAva : Ava} alt="Profile" /> */}
        {/* <p className="topbar__avatar-name">Browse</p> */}
        <img className="topbar__avatar-img" src={`${process.env.PUBLIC_URL}/img/wea_menu.png`} alt="Menu" />
        {/* <DownIcon className="topbar__icon" /> */}
      </button>
      {collapse && <button className="topbar__back" onClick={() => setCollapse(!collapse)} />}
      <Collapse isOpen={collapse} className="topbar__menu-wrap">
        <div className="topbar__menu">
          {!isMaintenance && <TopbarMenuLink title={t('footer.home')} /* icon="home" */ image="wea_home.png" exact path="/home" onClick={() => setCollapse(!collapse)} />}
          {!isMaintenance && <img className="menu__line" src={`${process.env.PUBLIC_URL}/img/wea_menu_line.png`} alt="Menu-line" />}
          {!isMaintenance && <TopbarMenuLink title={t('footer.world_cup')} /* icon="diamond" */ image="wea_world_cup.png" exact path="/world-cup" onClick={() => setCollapse(!collapse)} />}
          {!isMaintenance && <img className="menu__line" src={`${process.env.PUBLIC_URL}/img/wea_menu_line.png`} alt="Menu-line" />}
          {!isMaintenance && <TopbarMenuLink title={t('footer.weacourses')} /* icon="book" */ image="wea_interactive_course.png" exact path="/courses" onClick={() => setCollapse(!collapse)} />}
          {!isMaintenance && <img className="menu__line" src={`${process.env.PUBLIC_URL}/img/wea_menu_line.png`} alt="Menu-line" />}
          {!isMaintenance && !isDotCom && <TopbarMenuLink title={t('footer.other_events')} /* icon="book" */ image="wea_other_event_50px.png" exact path="/news" onClick={() => setCollapse(!collapse)} />}
          {!isMaintenance && !isDotCom && <img className="menu__line" src={`${process.env.PUBLIC_URL}/img/wea_menu_line.png`} alt="Menu-line" />}
          {!isMaintenance && !isDotCom && <TopbarMenuLink title={t('footer.donation')} /* icon="book" */ image="wea_donation_50px.png" exact path="/donation" onClick={() => setCollapse(!collapse)} />}
          {!isMaintenance && !isDotCom && <img className="menu__line" src={`${process.env.PUBLIC_URL}/img/wea_menu_line.png`} alt="Menu-line" />}

          {/* {!isMaintenance &&
            <TopbarMenuLink title={t('footer.training')}
              image="wea_teacher_training.png" 
              exact 
              path="/teacher-training" 
              onClick={() => setCollapse(!collapse)} />} */}
          {/* {!isMaintenance && <img className="menu__line" src={`${process.env.PUBLIC_URL}/img/wea_menu_line.png`} alt="Menu-line" />} */}
          {!isMaintenance && <TopbarMenuLink title={t('footer.aboutus')} /* icon="text-align-justify" */ image="wea_about_us.png" exact path="/about-us" onClick={() => setCollapse(!collapse)} />}
          {/* {!isMaintenance && <img className="menu__line" src={`${process.env.PUBLIC_URL}/img/wea_menu_line.png`} alt="Menu-line" />}
          {!isMaintenance && <TopbarMenuLink title={t('footer.memberships')} image="wea_membership_50px.png" exact path="/events/member" onClick={() => setCollapse(!collapse)} />} */}
          {!isMaintenance && <img className="menu__line" src={`${process.env.PUBLIC_URL}/img/wea_menu_line.png`} alt="Menu-line" />}
          {/* <TopbarMenuLink title={t('footer.ourteam')} icon="rocket" exact path="/ourteam" onClick={() => setcollapse(!collapse)} /> */}
          {/* <TopbarMenuLink title={t('footer.memberships')} icon="user" exact path="/memberships" onClick={() => setcollapse(!collapse)} /> */}
          {!isMaintenance && <TopbarMenuLink title={t('footer.contact')} icon="phone-handset" image="wea_contact.png" exact path="/contact" onClick={() => setCollapse(!collapse)} />}
          {/* <div className="topbar__link">
            <span className="topbar__link-icon lnr lnr-user" />
            <a href="https://store.joyajoy.com/signin" target="_blank" rel="noopener noreferrer">
              <p className="topbar__link-title">Sign In</p>
            </a>
          </div> */}
          {/* <div className="topbar__menu-divider" /> */}
          {/* <TopbarMenuLink title="Log Out" icon="exit" exact path="/logout" onClick={() => setcollapse(!collapse)} /> */}
        </div>
      </Collapse>
    </div>
  );
};

TopbarProfile.propTypes = {
  t: PropTypes.func.isRequired,
};

export default withTranslation('common')(TopbarProfile);
