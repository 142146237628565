/* eslint-disable camelcase */
import common_en from "./en/common.json";
import common_ja from "./ja/common.json";
import common_zh_hans from "./zh-hans/common.json";
import common_zh_hant from "./zh-hant/common.json";
import common_zh_tw from "./zh-TW/common.json";

export default {
  en: { common: common_en },
  ja: { common: common_ja },
  "zh-Hans": { common: common_zh_hans },
  "zh-Hant": { common: common_zh_hant },
  "zh-TW": { common: common_zh_tw},
};
