import React from 'react';
import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';
import { PropTypes } from 'prop-types';
// import TopbarSidebarButton from './TopbarSidebarButton';
import TopbarProfile from './TopbarProfile';
// import TopbarMail from './TopbarMail';
// import TopbarLanguage from './TopbarLanguage';
// import TopbarNotification from './TopbarNotification';
import TopbarNav from './tobar_nav/TopbarNav';

const TopbarWithNavigation = ({ t }) => (
  <div className="topbar topbar--navigation" id="topbar">
    <div className="topbar__wrapper">
      <div className="topbar__left">
        <Link className={t('logo.image')} to="/" />
        {/* <Link className={t('logo.image')} to="/">
          <img src={`${process.env.PUBLIC_URL}/img/${t('logo.image')}`} alt="" />
        </Link> */}
      </div>
      <div className="topbar__right">
        <TopbarNav />
      </div>
      <div className="topbar__right">
        {/* <TopbarNotification /> */}
        {/* <TopbarMail new /> */}
        <TopbarProfile />
        {/* <TopbarLanguage /> */}
      </div>
    </div>
  </div>
);

TopbarWithNavigation.propTypes = {
  t: PropTypes.func.isRequired,
};
// export default TopbarWithNavigation;
export default (withTranslation('common')(TopbarWithNavigation));
