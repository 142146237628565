/* eslint-disable no-return-assign */
import React, { useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';
// import NotificationSystem from 'rc-notification';
import Topbar from './topbar/Topbar';
import TopbarWithNavigation from './topbar_with_navigation/TopbarWithNavigation';
import Sidebar from './sidebar/Sidebar';
import SidebarMobile from './topbar_with_navigation/sidebar_mobile/SidebarMobile';
// import Customizer from './customizer/Customizer';
// import { BasicNotification } from '../../shared/components/Notification';
import { changeMobileSidebarVisibility, changeSidebarVisibility } from '../../redux/actions/ui/sidebarActions';
import { changeThemeToDark, changeThemeToLight } from '../../redux/actions/ui/themeActions';
// import { changeBorderRadius, toggleBoxShadow, toggleTopNavigation } from '../../redux/actions/ui/customizerActions';
import { CustomizerProps, SidebarProps } from '../../shared/prop-types/ReducerProps';
import BottomBar from './BottomBar';
// import { MembershipPropType } from '../../redux/propTypes';
// import { APPROVED } from '../../shared/constants/vendors';

// let notification = null;

// const showNotification = () => {
//   notification.notice({
//     content: <BasicNotification
//       title="Congratulations!"
//       message="Your membership has been approved to be upgraded!"
//     />,
//     duration: 5,
//     closable: true,
//     style: { top: 0, left: 'calc(100vw - 100%)' },
//     className: 'right-up',
//   });
// };

/* class Layout extends Component {
  static propTypes = {
    dispatch: PropTypes.func.isRequired,
    sidebar: SidebarProps.isRequired,
    customizer: CustomizerProps.isRequired,
    // theme: ThemeProps.isRequired,
    // membership: MembershipPropType,
  };

  // static defaultProps = {
  //   membership: null,
  // }

  componentDidMount() {
    // NotificationSystem.newInstance({}, n => notification = n);
  }

  componentWillUnmount() {
    // notification.destroy();
  }

  changeSidebarVisibility = () => {
    this.props.dispatch(changeSidebarVisibility());
  };

  changeMobileSidebarVisibility = () => {
    this.props.dispatch(changeMobileSidebarVisibility());
  };

  changeToDark = () => {
    this.props.dispatch(changeThemeToDark());
  };

  changeToLight = () => {
    this.props.dispatch(changeThemeToLight());
  };

  toggleTopNavigation = () => {
    this.props.dispatch(toggleTopNavigation());
  };

  changeBorderRadius = () => {
    this.props.dispatch(changeBorderRadius());
  };

  toggleBoxShadow = () => {
    this.props.dispatch(toggleBoxShadow());
  };

  render() {
    const { customizer, sidebar } = this.props;
    const layoutClass = classNames({
      layout: true,
      'layout--collapse': sidebar.collapse,
      'layout--top-navigation': customizer.topNavigation,
    });

    return (
      <div className={layoutClass}>
        {this.props.customizer.topNavigation ?
          <TopbarWithNavigation
            changeMobileSidebarVisibility={this.changeMobileSidebarVisibility}
          /> :
          <Topbar
            changeMobileSidebarVisibility={this.changeMobileSidebarVisibility}
            changeSidebarVisibility={this.changeSidebarVisibility}
          />
        }
        {this.props.customizer.topNavigation ?
          <SidebarMobile
            sidebar={sidebar}
            changeToDark={this.changeToDark}
            changeToLight={this.changeToLight}
            changeMobileSidebarVisibility={this.changeMobileSidebarVisibility}
          /> :
          <Sidebar
            sidebar={sidebar}
            changeToDark={this.changeToDark}
            changeToLight={this.changeToLight}
            changeMobileSidebarVisibility={this.changeMobileSidebarVisibility}
          />
        }
        <BottomBar/>
      </div>
    );
  }
} */

const Layout = ({
  dispatch,
  sidebar,
  customizer,
  // theme,
  // membership,
}) => {
  useEffect(() => {
    // NotificationSystem.newInstance({}, n => notification = n);
    // return function destroy() {
    //   notification.destroy();
    // }
  });

  const thischangeSidebarVisibility = () => {
    dispatch(changeSidebarVisibility());
  };

  const thischangeMobileSidebarVisibility = () => {
    dispatch(changeMobileSidebarVisibility());
  };

  const changeToDark = () => {
    dispatch(changeThemeToDark());
  };

  const changeToLight = () => {
    dispatch(changeThemeToLight());
  };

  /* toggleTopNavigation = () => {
    dispatch(toggleTopNavigation());
  };

  changeBorderRadius = () => {
    dispatch(changeBorderRadius());
  };

  toggleBoxShadow = () => {
    dispatch(toggleBoxShadow());
  }; */

  const layoutClass = classNames({
    layout: true,
    'layout--collapse': sidebar.collapse,
    'layout--top-navigation': customizer.topNavigation,
  });

  return (
    <div className={layoutClass}>
      {/* <Customizer
        customizer={customizer}
        sidebar={sidebar}
        theme={theme}
        changeSidebarVisibility={this.changeSidebarVisibility}
        toggleTopNavigation={this.toggleTopNavigation}
        changeToDark={this.changeToDark}
        changeToLight={this.changeToLight}
        changeBorderRadius={this.changeBorderRadius}
        toggleBoxShadow={this.toggleBoxShadow}
      /> */}
      {customizer.topNavigation ?
        <TopbarWithNavigation
          changeMobileSidebarVisibility={() => thischangeMobileSidebarVisibility}
        /> :
        <Topbar
          changeMobileSidebarVisibility={() => thischangeMobileSidebarVisibility}
          changeSidebarVisibility={() => thischangeSidebarVisibility}
        />
      }
      {customizer.topNavigation ?
        <SidebarMobile
          sidebar={sidebar}
          changeToDark={() => changeToDark}
          changeToLight={() => changeToLight}
          changeMobileSidebarVisibility={() => thischangeMobileSidebarVisibility}
        /> :
        <Sidebar
          sidebar={sidebar}
          changeToDark={() => changeToDark}
          changeToLight={() => changeToLight}
          changeMobileSidebarVisibility={() => thischangeMobileSidebarVisibility}
        />
      }
      <BottomBar/>
    </div>
  );
};

Layout.propTypes = {
  dispatch: PropTypes.func.isRequired,
  sidebar: SidebarProps.isRequired,
  customizer: CustomizerProps.isRequired,
  // theme: ThemeProps.isRequired,
  // membership: MembershipPropType,
};

// Layout.defaultProps = {
//   membership: null,
// }

export default withRouter(connect(state => ({
  customizer: state.ui.customizer,
  sidebar: state.ui.sidebar,
  theme: state.ui.theme,
}))(Layout));
